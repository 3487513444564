import React from 'react';
import { isMobile } from 'react-device-detect';
import '../css/styles.css'

class TopNav extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            bottomBorder: false,
            headerDown: true,
            scrollPos: 0,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        let scrollTop = window.scrollY;

        if (scrollTop > this.state.scrollPos) {
            this.setState({headerDown: false, scrollPos: scrollTop});
        } else {
            if (scrollTop === 0) {
                this.setState({bottomBorder: false, headerDown: true, scrollPos: scrollTop})
            } else {
                console.log('In');
                this.setState({bottomBorder: true, headerDown: true, scrollPos: scrollTop})
            }
        }

        console.log(scrollTop);
    }

    render() {
        let classNames = this.state.headerDown ? "header-down " : "header-up ";
        classNames += this.state.bottomBorder ? "header-off" : "";
        return (
            <header className={classNames}>
                <div className="logo">
                    <img className={isMobile ? "logo-image-mobile" : "logo-image"} src={require('../images/logo.png')} />
                </div>
            </header>
        );
    }
}

export default TopNav;
