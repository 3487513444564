import React, {useEffect, useState} from 'react';
import './App.css';
import { isMobile } from 'react-device-detect';
import TopNav from "./components/TopNav";

function App() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const imgs = [
            require('./images/footer-hero.svg'),
            require('./images/header-hero.svg')
        ];

        cacheImages(imgs);
    });

    const cacheImages = async (srcArray) => {
      const promises = await srcArray.map((src) => {
          return new Promise(function (resolve, reject) {
              const img = new Image();

              img.src = src;
              img.onload = resolve();
              img.onerror = reject();
          });
      });

      await Promise.all(promises);

      setIsLoading(false);
    };

  return (
    <div className="App">
        {isLoading
            ?
            <div style={{background: 'linear-gradient(to right, #F758A5, #D9B6FF)'}}/>
            :
            <div>
                <TopNav />
                <div style={{height: 1200, justifyContent: 'center'}}>
                    <div style={{color: 'white', textAlign: 'center', fontSize: isMobile ? 70 : 150, paddingTop: 200}}>
                    COMING SOON
                    </div>
                </div>
            </div>
        }
    </div>
  );
}

export default App;
